<script>
import Swal from 'sweetalert2';
import Dataset from "vue-dataset/dist/es/Dataset.js";
import DatasetItem from "vue-dataset/dist/es/DatasetItem.js";
// import DatasetShow from "vue-dataset/dist/es/DatasetShow.js";
export default {
  components: {
    Dataset,
    DatasetItem,
    // DatasetShow,
  },
   props: { reloadRooms: {},
    lifebaseList: {},
    roomType: {},
  },
  data() {
    return {
      rooms : [],
      blocs : [],
      selectedRows: null,
      search: '',
      defaultShow: 5,
      sizeToShow : 5,
      totalPages : 0,
      currentPage: 1,
      pageSize: 5,
      pageOptions: [10, 25, 50, 100],
      showModal: false,
      active:false,
      cols: [
        {
          name: "Base de Vie ",
          field: "lifebaseName",
          sort: ''
        },
        {
          name: "N°",
          field: "number",
          sort: ''
        },
        {
          name: "Bloc",
          field: "blocName",
          sort: ''
        },
        {
          name: "Téléphone",
          field: "phone",
          sort: ''
        },
        {
          name: "Type",
          field: "type",
          sort: ''
        },
        {
          name: "État",
          field: "etat",
          sort: ''
        },
        {
          name: "Status",
          field: "status",
          sort: ''
        },
      ],
      types : [],
      selectedIndex:-1,
      lifebases : [],
      filterForm : {
        roomTypeId: '',
        campId: '',
        blocId: '',
        etat : '',
        dateRange :''
      },
      customDateRangeShortcuts: [
        { key: "thisWeek", label: "Cette semaine", value: "isoWeek" },
        { key: "lastWeek", label: "Semaine dernière", value: "-isoWeek" },
        { key: "last7Days", label: "7 derniers jours", value: 7 },
        { key: "last30Days", label: "30 derniers jours", value: 30 },
        { key: "thisMonth", label: "Ce mois", value: "month" },
        { key: "lastMonth", label: "Dernier mois", value: "-month" },
        { key: "thisYear", label: "Cette année", value: "year" },
        { key: "lastYear", label: "L'année dernière", value: "-year" },
      ],
      etats :[
        {
          id : 'other',
          name : 'Libre'
        },
        {
          id : 'checkin',
          name : 'Occupée'
        }
      ]
    }
  },
  created: function() {
    this.refreshRoomsTable();
  },
  watch : {
    selectedRows(){
        if(this.selectedRows.length && undefined != this.selectedRows[0]){
         this.$emit('selectedRooms',this.selectedRows)
        }else{
          this.$emit('selectedRooms',[])
        }
      },
    reloadRooms:{
        handler(val){
          if(val){
            this.refreshRoomsTable();
          }
        }
      },
    lifebaseList:{
        handler(val){
          this.lifebases = val;
        }
      },
    roomType:{
        handler(val){
          this.types = val;
        }
      }
  },
  methods: {
    selectRow(row,index){
      if(this.selectedIndex == index) {
        this.selectedRows = []; this.selectedIndex = -1; 
        this.$emit('selectedRooms',[]) 
      }
      else {
        this.selectedRows = [row];
        this.$emit('selectedRooms',this.selectedRows)
        this.selectedIndex = index;
      }
    },
    
    onchange(){
      this.defaultShow = parseInt(this.sizeToShow);
       this.refreshRoomsTable();
       },
    clearSearch (){
      this.search                = "";
      this.filterForm.roomTypeId = "";
      this.filterForm.campId     = "";
      this.filterForm.etat       = "";
      this.refreshRoomsTable();
    },
    searchUnit (){
    
      if(this.search.length > 3)
      this.refreshRoomsTable();
      else if(this.search.length == 0)
      this.refreshRoomsTable();
    },
    refreshRoomsTable(page = 1){
      var show   = this.defaultShow;
      let loader = this.$loading.show({
        color: '#000000',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 999,
      })
      this.$http.post('/infrastructure/rooms/paginate?page=' + page+"&show=" + show +"&roomTypeId=" + this.filterForm.roomTypeId +
                  "&from="+this.filterForm.dateRange.start+"&to="+this.filterForm.dateRange.end+"&camp="+this.filterForm.campId+"&bloc="+this.filterForm.blocId+"&etat="+this.filterForm.etat)
          .then(res => {
            this.rooms = res.data
            this.$emit("reloadRoomsDone" , false)
            loader.hide();
            })
          .catch(() => {
            loader.hide();
              // error.response.status Check status code
              
          }).finally(() => {
              //Perform action in always
          });
    },
   /*  refreshRoomsTable(){
      let loader = this.$loading.show({
        color: '#000000',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 999,
      })
      this.$http.post('/infrastructure/rooms/list')
          .then(res => {
            this.rooms = res.data.original.list
            this.$emit("reloadRoomsDone" , false)
            loader.hide();
            })
          .catch(() => {
            loader.hide();
              // error.response.status Check status code
              
          }).finally(() => {
              //Perform action in always
          });
    }, */
    getBlocs() {
      var _this = this;
      
      this.$http
        .post("/infrastructure/blocs/list",{'life_base_id':this.filterForm.campId})
        .then((res) => {
          // loader.hide();
          _this.blocs = res.data.original.list;
        })
        .catch((error) => {
          // loader.hide();
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {
          // loader.hide();
        });
    },
    deleteRoom(roomName, roomUid) {
      Swal.fire({
        title: "Êtes-vous sûr de supprimer " + roomName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("/infrastructure/rooms/delete/" + roomUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  Swal.fire("Félicitations!", res.data.original.msg, "success");
                  this.refreshRoomsTable();
                  break;

                case 500:
                  Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            loader.hide();
            })
            .catch((error) => {
            loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    
  },
};
</script>

<template>
  <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des Chambres</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                 <dataset v-slot="{ ds }" :ds-data="rooms.data" >
                  <div class="row">
                      <div class="col-xs-12 col-md  ">
                        <div class="form-group">
                          <select
                            v-model="filterForm.roomTypeId"
                            class="custom-select" aria-placeholder="Type">
                            <option v-for="(type,i) in types" :value="type.id" :key="i">
                              {{ type.designation }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-xs-12 col-md  ">
                        <div class="form-group">
                          <select aria-placeholder="Base De Vie"
                            v-model="filterForm.campId"
                            @change="getBlocs()"
                            class="custom-select">
                            <option v-for="(type,i) in lifebaseList" v-bind:value="type.id" v-bind:key="i">
                              {{ type.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-xs-12 col-md  ">
                        <div class="form-group">
                          <select placeholder="Bloc"
                            v-model="filterForm.blocId"
                            class="custom-select">
                            <option v-for="(bloc,i) in blocs" v-bind:value="bloc.id" v-bind:key="i">
                              {{ bloc.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-xs-12 col-md  ">
                        <div class="form-group">
                          <VueCtkDateTimePicker
                            locale="fr"
                            v-model="filterForm.dateRange"
                            style="display:inline-block;margin-bottom: 20px;"
                            :range="true"
                            color="#34495e"
                            format='YYYY-MM-DD'
                            formatted='ll'
                            :no-label="true"
                            :custom-shortcuts="customDateRangeShortcuts"
                            :auto-close="false"
                          ></VueCtkDateTimePicker>
                        </div>
                      </div>
                      <div class="col-xs-12 col-md  ">
                        <div class="form-group">
                          <select aria-placeholder="Base De Vie"
                            v-model="filterForm.etat"
                            class="custom-select">
                            <option selected></option>
                            <option v-for="(type,i) in etats" v-bind:value="type.id" v-bind:key="i">
                              {{ type.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-xs-12 col-md-1">
                        <b-button variant="primary " @click="searchUnit"> <i class="fas fa-search" ></i></b-button>
                        <b-button variant="warning  ml-1" @click="clearSearch"> <i class="fas fa-times-circle" ></i></b-button>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="">
                        <table class="table table-hover d-md-table">
                          <thead>
                            <tr>
                              <th v-for="(th) in cols" :key="th.field">
                                {{ th.name }} 
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                <td>{{ row.lifebaseName }}</td>
                                <td>{{ row.number }}</td>
                                <td>{{ row.blocName }}</td>
                                <td>{{ row.phone }}</td>
                                <td>{{ row.type }}</td>
                                <td><span v-html="row.checkRoom"></span></td>
                                <td><span v-html="row.status"></span></td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                </dataset>
                  <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                    <!-- <dataset-info class="mb-2 mb-md-0" /> -->
                    <div class="d-flex flex-md-row flex-column justify-content-between align-items-center col-2">
                    <select name="" class="form-control mr-1 ml-1" @change="onchange"  id="" v-model="sizeToShow">
                      <option value="5">5</option>
                      <option value="10">10</option>
                    </select>
                    </div>
                    <div class="d-flex flex-md-row flex-column justify-content-between align-items-center ">
                    <pagination
                        :data="Object.assign({},rooms)"
                        :limit="defaultShow"
                        class="float-right"
                        @pagination-change-page="refreshRoomsTable"
                      />
                    </div>
                  </div>
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

      
      </div>
    </div>

</template>