<script>
import Swal from 'sweetalert2';
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js' 
import Multiselect from "vue-multiselect";
import { fetchEquipmentsApi } from "@/api/common";
// import SideButtons from '../../../../../../components/side-buttons.vue';
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  components: {Multiselect ,  Dataset, DatasetItem,/* DatasetInfo, */DatasetPager,/* DatasetSearch, */ DatasetShow/* ,SideButtons */},
  props: { reloadRooms: {},
  selectedRoom : {},
  selectedEquipements :{}
  },
  data() {
    return {
      roomUuid: null,
      equipmentsList : [],
      equipments: [],
      equipmentStatus: [
        {
          id: 'new',
          name: 'Nouveau'
        },
        {
          id: 'used',
          name: 'Utilisé'
        },
      ],
      selectedRows: null,
      totalPages : 0,
      currentPage: 1,
      pageSize: 5,
      pageOptions: [10, 25, 50, 100],
      showModal: false,
      active:false,
      cols: [
        {
          name: "Désignation",
          field: "number",
          sort: ''
        },
        {
          name: "N° Série",
          field: "blocName",
          sort: ''
        },
        {
          name: "N° Inventaire",
          field: "type",
          sort: ''
        },
        {
          name: "Status",
          field: "status",
          sort: ''
        },
      ],
      selectedIndex:-1,
      form:{
        equipments : [],
        serial_no : "",
        inventory_no : "",
        status : "",
      },
      lastSelect :null,
      disabled : true,
      canDelte : false,
      disabledAdd : false,
      disabledUpdate : false,
      disabledCancel : true,
      disabledDelete : true,
      add : false,
      edit : false,
      destroy : false,
      send : false,
      have : false,
      dp : 1,
      submitted: true,
    }
  },
  validations: {
    form: {
      equipments: { required },
      serial_no: { required },
      inventory_no: { required },
      status  :{required}
    },
  },
  created: function() {
    // this.refreshEquipmentsTable();
    this.fetchEquipmentsList();
  },
  watch : { 
    add(){
      this.addBtn()
    },
    duplicate(){
      this.duplicateBtn()
    },
    edit(){
      this.editBtn()
    },
    destroy(){
      this.delete()
    },
    /* selectedEquipements:{
        handler(val){
          this.equipmentsList = [];
          if(val){
           this.equipmentsList =  Object.keys(val[0]).map(key => val[0]);
           console.log(this.equipmentsList);
          }
        }
    }, */
    selectedRoom:{
        handler(val){
          if(val){
            if(val.equipments == null)
            this.equipmentsList = [];
            // else
            
            
            this.roomUuid       = val.id;  
            this.refreshEquipmentsTable();
          }
        }
    },
    selectedRows(){
        if(this.selectedRows.length && undefined != this.selectedRows[0]){
        //  this.$emit('selectedRooms',this.selectedRows)
        }else{
          // this.$emit('selectedRooms',[])
        }
      },
    reloadRooms:{
        handler(val){
          if(val){
            // this.refreshEquipmentsTable();
          }
        }
      }
  },
  methods: {
     
    addBtn(){
      this.disabled       = false;
      this.disabledUpdate = false;
      this.disabledAdd    = true;
      this.disabledEdit   = true;
      this.disabledCancel = false;
      this.disabledDelete = true;
      // this.selectedRows = []; this.selectedIndex = -1; 
      this.clearForms();
    },
    duplicateBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledUpdate = true;
      this.form.uuid  = "";
      this.disabledCancel = true;
      this.disabledDelete = true;
    },
    editBtn(){
      this.disabled       = false;
      this.disabledAdd    = false;
      this.disabledUpdate = true;
      this.disabledEdit   = true;
      this.disabledCancel = false;
      this.disabledDelete = true;
      

    },
    cancelBtn(){
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledUpdate = false;
      this.disabledEdit   = true;
      this.disabledCancel = true;
      if(this.lastSelect != null){
        // this.form           = this.lastSelect;
        this.selectRow(this.selectedRows,this.selectedIndex)
        this.disabledEdit   = false;
        this.disabledDelete = false;

      }
    },

    
    clearForms(){
      this.form.equipments   = [];
      this.form.serial_no    = "";
      this.form.inventory_no = "";
      this.form.status       = "";
    },
    selectRow(row,index){
        this.disabled       = true;
        this.disabledAdd    = false;
        this.disabledCancel = true;
      
      if(this.selectedIndex == index) {
        this.selectedRows = []; this.selectedIndex = -1; 
        this.clearForms();
        this.disabledDelete = true;
        this.disabledEdit   = true;
      }
      else {
        this.form.equipments   = row;
        this.form.serial_no    = row["serial_no"];
        this.form.inventory_no = row["inventory_no"];
        this.form.status       = this.equipmentStatus.filter( (x) => (x.id == row["status"]))[0] ;
        this.selectedRows      = row;
        // this.lastSelect     = this.form ;
        this.selectedIndex     = index;
        this.lastSelect        = this.form;
        this.disabledDelete    = false;
        this.disabledEdit      = false;
      }
    },
    fetchEquipmentsList() {
      fetchEquipmentsApi()
        .then((res) => (this.equipments = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    resetForm(){
      this.selectedRows   = []; 
      this.selectedIndex  = -1; 
      this.disabled       = true;
      this.canDelte       = false;
      this.disabledAdd    = false;
      this.disabledEdit   = true;
      this.disabledUpdate = false;
      this.disabledCancel = true;
      this.disabledDelete = true;
      this.clearForms();
    },
    refreshEquipmentsTable(){
      let loader = this.$loading.show({
        color: '#000000',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 999,
      })
      this.$http.post('/infrastructure/rooms/getSingleRoomDetails/' +this.roomUuid)
          .then(res => {
            this.resetForm();
            // console.log(res.data.original.room.equipments);
            if(res.data.original.room.equipments == null)
            this.equipmentsList = [];
            else
            this.equipmentsList = res.data.original.room.equipments;
            loader.hide();
            })
          .catch(() => {
            loader.hide();
              // error.response.status Check status code
              
          }).finally(() => {
              //Perform action in always
          });
    },

    formSubmit() {
      this.form["room_id"] = this.roomUuid;
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submitted = false;
        let loader = this.$loading.show({
          color: '#000000',
          loader: 'spinner',
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999,
        })
        this.$http
          .post("/infrastructure/rooms/addEquipments", this.form)

          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                // Swal.fire("Ajouter!", res.data.original.msg, "success");
                // this.reloadRooms = true
                this.refreshEquipmentsTable();
                this.disabled       = true;
                this.clearForms();
                break;

              case 500:
                this.$toast.error(res.data.original.msg);
                // Swal.fire("Erreur!", res.data.original.msg, "error");
                break;
            }
            loader.hide();
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },
    
    deleteKitchenEquipment() {
      this.form["room_id"] = this.roomUuid;
      if(this.roomUuid == null){
          this.$toast.error("vous devez choisir une chambre");
        return 0;
      }
      if( this.form.equipments.id == ""){
          this.$toast.error("vous devez choisir un équipement");
        return 0;
      }
      Swal.fire({
        title: "Êtes-vous sûr de supprimer : " + this.form.equipments.name + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("/infrastructure/rooms/deleteEquipments" , this.form)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.clearForms();
                  this.$toast.success(res.data.original.msg);
                  this.refreshEquipmentsTable();
                  this.disabled       = true;
                break;
                case 500:
                  this.$toast.error(res.data.original.msg);
                break;
              }
              loader.hide();
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    updateRoom() {
      this.form["room_id"] = this.roomUuid;
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submitted = false;
        let loader = this.$loading.show({
          color: '#000000',
          loader: 'spinner',
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999,
        })
        this.$http
          .post("/infrastructure/rooms/updateEquipments", this.form)

          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                // Swal.fire("Ajouter!", res.data.original.msg, "success");
                this.refreshEquipmentsTable();
                this.disabled       = true;
                // this.clearForms();
                break;

              case 500:
                this.$toast.error(res.data.original.msg);
                // Swal.fire("Erreur!", res.data.original.msg, "error");
                break;
            }
            loader.hide();
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },
    
  },
};
</script>
<style >
  .finish-button{
    background-color:#43A047 !important;
    border-color: #43A047 !important;
  }
  #StickyH {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
  .rounded-pillH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #8FC412 ;
  }
  .btn-editH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteH #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-printH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
   .btn-cancelH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
</style>
<template>
  <div class="row">
      <div class="col-12">
         <!-- <SideButtons
                  :select="disabledAdd"  :DP="false" :canEdit="disabledDelete" :save="send" :ODS="false"   @add="add = $event" @duplicate="duplicate = $event" @edit=" edit = $event"  @delete=" destroy = $event" /> -->
        <ul id="StickyH">
            <li>
              <button type="button" :disabled="disabledAdd && !$can('create_hosting_room_equipments')" @click="addBtn" class="btn btn-addH rounded-pillH float-right mr-1 mt-1">
              <i class="fas fa-plus text-white"></i>
              </button>
            </li>
            <li>
              <button type="button" :disabled="disabledDelete && !$can('update_hosting_room_equipments')" @click="duplicateBtn" class="btn btn-duplicateH rounded-pillH float-right mr-1 mt-1">
              <i class="far fa-copy text-white"></i>
              </button>
            </li>
            <li>
              <button type="button" :disabled="disabledDelete && !$can('delete_hosting_room_equipments')" @click="editBtn" class="btn btn-editH rounded-pillH float-right mr-1 mt-1">
                <i class="fas fa-pencil-alt text-white" style=""></i>
              </button>
            </li>
            <li>
              <button type="button" :disabled="disabledDelete" @click="deleteKitchenEquipment" class="btn btn-deleteH rounded-pillH float-right mr-1 mt-1">
                  <i class="far fa-trash-alt text-white"></i>
              </button>
            </li>
          </ul>    
        <dataset v-slot="{ ds }" :ds-data="equipmentsList" >
          <div class="row">
            <div class="col-md-6 mb-2 mb-md-0">
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="">
                <table class="table table-hover d-md-table table-striped">
                  <thead>
                    <tr>
                      <th v-for="(th) in cols" :key="th.field">
                        {{ th.name }} 
                      </th>
                    </tr>
                  </thead>
                  <dataset-item tag="tbody">
                    <template #default="{ row, rowIndex }">
                      <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                        <td>{{ row.name }}</td>
                        <td>{{ row.serial_no }}</td>
                        <td>{{ row.inventory_no }}</td>
                        <td>{{ row.statusTitle }}</td>
                      </tr>
                    </template>
                  </dataset-item>
                </table>
              </div>
            </div>
          </div>
          <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
            <!-- <dataset-info class="mb-2 mb-md-0" /> -->
            <dataset-show :ds-show-entries="5" />
            <dataset-pager />
          </div>
        </dataset>
        <h4 class="card-title mt-3  pb-2 col-12">
          Equipement Details
        </h4>
        <fieldset :disabled="disabled">
        <div class="row " >
        <div class="form-group col-xs-12 col-md-6 col-lg-3">
          <label for="message">Equipement</label>
          <multiselect
            :select-label="''"
            v-model="form.equipments"
            :searchable="true"
            track-by="id"
            label="name"
            :options="equipments"
            placeholder="Selectionner"
            :allow-empty="false"
            :class="{'disabledMS': disabled,}"
          >
            <template slot="singleMealType" slot-scope="{ eqp }" >{{ eqp.name }}</template >
          </multiselect>
        </div>
        <div class="form-group col-xs-12 col-md-6 col-lg-3">
          <label for="resume">N° Série</label>
        <input id="subject" v-model="form.serial_no" type="text" class="form-control" :class="{'disabledMS': disabled,}" />
        </div>

        <div class="form-group col-xs-12 col-md-6 col-lg-3">
          <label for="subject">N° Inventaire</label>
          <input id="subject" v-model="form.inventory_no" type="text" class="form-control" :class="{'disabledMS': disabled,}" />
        </div>

        <div class="form-group col-xs-12 col-md-6 col-lg-3">
          <label for="message">Status</label>
          <multiselect 
            :select-label="''" 
            v-model="form.status" 
            :searchable="true" 
            track-by="id" 
            label="name" 
            :class="{'disabledMS': disabled,}" 
            :options="equipmentStatus" 
            placeholder="Selectionner" 
            :allow-empty="false"
          >
            <template slot="singleMealType" slot-scope="{ type }" >{{ type.name }}</template >
          </multiselect>
        </div>
        </div>
        
        <div class="button-items float-right ">
          <div class="col-md-12" v-show="!disabled"> 
            <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
              <i class="fas fa-times text-white label-icon" ></i> Cancel
            </button>
            
          <button
              class="btn btn-primary float-right btn-label"
              type="button"
              @click="formSubmit"
              v-show="disabledAdd && $can('create_hosting_room_equipments')"
            >
              <i class="far fa-save label-icon "></i> Enregistrer
            </button>
            <button
              class="btn btn-primary float-right btn-label"
              type="button"
              @click="updateRoom"
              v-show="disabledUpdate && $can('update_hosting_room_equipments')"
            >
              <i class="far fa-save label-icon "></i> Enregistrer
            </button>
            
          </div>
            
        </div>
      </fieldset>
      </div>
    </div>

</template>